<template>
  <div class="proDetail-containter">
    <nav-menu></nav-menu>
    <div class="proDetail-body">
      <div class="containter flex-b">
        <div class="proDetail-left">
          <div class="proDetail-head flex-a">
            <img src="./../assets/product_ico1.png" alt="">
            <h2>产品概要</h2>
          </div>
          <div class="proDetail-list">
            <div class="proDetail-item">
              <span>发行机构：</span>{{ info.fnaName + '股份有限公司' }}
            </div>
            <div class="proDetail-item">
              <span>产品类型：</span>{{ info.codeName }}
            </div>
            <div class="proDetail-item">
              <span>费率：</span>{{ info.chPro }}%/笔
            </div>
            <div class="proDetail-item">
              <span>最低手续费：</span>{{ info.minCh }}
            </div>
            <div class="proDetail-computed flex-a">
              <span class="txt">费用计算：</span>
              <el-input v-model="money" placeholder="请输入保证金金额（元）"></el-input>
              <span class="btns" @click="compute">计算</span>
              <span v-if="show" style="font-size: 30px;color: #e42494;padding-left: 30px;">{{ charge + '.00' }}</span>
            </div>
            <div class="proDetail-btn">
              <span class="btns" style="width:80px;background:#E6A23C;" @click="apply">立即申请</span>
            </div>
          </div>
          <div class="proDetail-head flex-a">
            <img src="./../assets/product_ico2.png" alt="">
            <h2>详细介绍</h2>
          </div>
          <div class="proDetail-content" v-html="info.remark">
          </div>
        </div>
        <div class="proDetail-right">
          <div class="head">
            <img :src="info.finaUrl" alt="">
          </div>
          <div class="content">
            <div class="title">
              <span>申请流程</span>
            </div>
            <div class="list">
              <div class="item">
                <span>1</span>
                选择标段项目
              </div>
              <div class="item">
                <span>2</span>
                选择金融产品
              </div>
              <div class="item">
                <span>3</span>
                提交申请意向
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { getProDetail, getProDetailByToken } from './../lib/api/product'
export default {
  name: 'proDetail',
  data() {
    return {
      info: {},
      money: '',
      charge: 0,
      show: false
    }
  },
  created() {
    if (window.localStorage.getItem("token")) {
      this.getProDetailByToken(this.$route.query.code, this.$route.query.id)//产品-登录
    } else {
      this.getProDetail(this.$route.query.code, this.$route.query.id)//产品-未登录
    }
  },
  mounted() {

  },
  destroyed() {
  },
  methods: {
    //详情-未登录
    getProDetail(code, id) {
      getProDetail(code, id).then(res => {
        if (res.code == 200) {
          this.info = res.data
        }
      })
    },
    //详情-登录
    getProDetailByToken(code, id) {
      getProDetailByToken(code, id).then(res => {
        if (res.code == 200) {
          this.info = res.data
        }
      })
    },
    //立即申请
    apply() {
      this.$router.push({ path: '/project', query: { type: 0, code: this.$route.query.code } })
    },
    //计算
    compute() {
      if (this.money > 0) {
        var n = Number(this.money) * Number(this.info.chPro) * 0.01
        this.charge = n < this.info.minCh ? this.info.minCh : n
        this.show = true
      }
    }
  },
  computed: {

  }
}
</script>

<style lang="less">
.proDetail-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .proDetail-body {
    padding: 20px 0;

    .containter {
      padding: 20px;

      .proDetail-left {
        width: 74%;

        .proDetail-head {
          border-bottom: 1px solid #d1d4dc;

          img {
            margin-right: 10px;
          }
        }

        .proDetail-list {
          margin: 20px auto;

          .proDetail-item {
            font-size: 14px;

            span {
              display: inline-block;
              width: 150px;
              text-align: right;
              margin-bottom: 15px;
              font-size: 15px;
            }
          }

          .proDetail-computed {
            box-sizing: border-box;
            height: 50px;
            border-radius: 5px;
            // margin-top: 5px;
            background-color: #f7f6f6;
            position: relative;

            .txt {
              display: inline-block;
              width: 150px !important;
              text-align: right;
              font-size: 15px;
              color: #0069d5;
            }

            .el-input {
              width: 40%;
            }

            .btns {
              display: inline-block;
              width: 60px;
              height: 35px;
              line-height: 35px;
              color: #fff;
              text-align: center;
              border-radius: 5px;
              background-color: #2c93e7;
              margin-left: 10px;
              font-size: 15px;
            }

            .btns:hover {
              cursor: pointer;
            }
          }

          .proDetail-btn {
            .btns {
              display: inline-block;
              width: 60px;
              height: 35px;
              line-height: 35px;
              color: #fff;
              text-align: center;
              border-radius: 5px;
              background-color: #2c93e7;
              margin: 10px 0 0 70px;
              font-size: 15px;
            }

            .btns:hover {
              cursor: pointer;
            }
          }
        }

        .proDetail-content {
          padding: 12px 0 5px 0;
          line-height: 28px;
          padding-left: 55px;
          font-size: 15px;

          span {
            color: #FF0000;
          }
        }
      }

      .proDetail-right {
        width: 24%;

        .head {
          img {
            width: 100%;
          }
        }

        .content {
          background-color: #f3f6fa;

          .title {
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            height: 45px;
            line-height: 45px;
            text-align: center;
            background: url(./../assets/flow_bg.png) no-repeat center top;
          }

          .list {
            box-sizing: border-box;
            padding: 5px 47px 20px 37px;
            min-height: 520px;

            .item {
              position: relative;
              height: 44px;
              line-height: 44px;
              font-size: 16px;
              padding-left: 50px;
              margin-top: 25px;
              border-radius: 25px;
              background-image: linear-gradient(to right, transparent, #d5e2e9);

              span {
                font-family: "OPPOSans-Bold";
                position: absolute;
                top: 50%;
                left: 0;
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                color: #fff;
                transform: translateY(-50%);
                text-align: center;
                background: url('./../assets/num_bg.png') no-repeat;
              }
            }
          }
        }
      }
    }
  }
}
</style>
